























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapGetters, mapMutations, MutationMethod } from 'vuex';

import CreateNewReport from '@/components/fmh_components/common/CreateNewReport/CreateNewReport.vue';
import Error from '@/components/fmh_components/common/Error/Error.vue';
import GeneralTable from '@/components/fmh_components/common/GeneralTable/GeneralTable.vue';
import Loader from '@/components/fmh_components/common/Loader/Loader.vue';
import FMHAdvancedTable from '@/components/fmh_components/common/FMHAdvancedTable/FMHAdvancedTable.vue';
import ReportsIterator from '@/components/fmh_components/ReportsIterator/ReportsIterator.vue';
import ModalConfirm from '../../../wettbewerbsvergleich/src/components/common/ModalConfirm/ModalConfirm.vue';
import SnackBar from '@/components/fmh_components/common/SnackBar/SnackBar.vue';

import {
  BASE_BLACK_COLOR,
  SNACK_BAR_DATA,
  TEXT_ACTION_BUTTONS,
} from '@/utils/constants';
import { getType, savePDF, exportXLS, reportDatePdf } from '@/utils/helpers';
import { CurrentReport } from '@/interfaces';

interface RequiredPayload {
  id: string;
  productType: string;
}
@Component({
  components: {
    ReportsIterator,
    CreateNewReport,
    Error,
    GeneralTable,
    Loader,
    FMHAdvancedTable,
    ModalConfirm,
    SnackBar,
  },
  computed: {
    ...mapGetters({
      allReports: 'reports/allReportsBaugeld',
      updatedReportsBaugeld: 'reports/updatedReportsBaugeld',
      report_type: 'reports/report_type',
      dateNow: 'dateNow/getDateNowBaugeld',
      loading: 'reports/loadingReportsBaugeld',
      error: 'reports/error',
      isAuthorized: 'auth/isAuthorized',
      uploadedReports: 'reports/getUploadedReports',
      snackbarData: 'reports/getSnackbarData',
      user: 'auth/getUser',
      abortControllers: 'reports/getAbortControllers',
    }),
  },
  methods: {
    ...mapActions({
      fetchReportTypes: 'reports/fetchReportTypes',
      fetchAllReports: 'reports/fetchAllReports',
      deleteReport: 'reports/deleteReport',
      fetchCopyReport: 'reports/fetchCopyReport',
    }),
    ...mapMutations({
      setSnackBarData: 'reports/setSnackBarData',
      deleteReportSuccess: 'reports/fetchDeleteReportSuccess',
      clearTableData: 'baugeld/clearTableData',
      setDateNowFromBaugeld: 'dateNow/setDateNowFromBaugeld',
      clearResult: 'reports/clearResult',
      setReportType: 'reports/setReportType',
      setLoading: 'reports/loadingAllReportsBaugeld',
      setError: 'reports/error',
      clearControllers: 'reports/clearControllers',
      clearReports: 'reports/clearReports',
    }),
  },
})
export default class Baugeld extends Vue {
  [x: string]: any;
  dialog: Boolean = false;
  currentObjForSaving: CurrentReport = {};
  selected: String[] = [];
  date: String = `${this.$date(new Date())}`;
  document = {
    show: false,
    headers: [],
    rows: [],
    type: '',
  };
  snackBarData = SNACK_BAR_DATA;
  tags: String[] = [];
  color = BASE_BLACK_COLOR;
  isLoadingPdf: Boolean = false;
  isAuthorized!: Boolean;
  allReports!: Array<Object>;
  isDeleting = false;
  textActionButtons = TEXT_ACTION_BUTTONS;
  abortControllers!: { abort: Function }[] | null;

  deleteReport!: Function;
  fetchCopyReport!: (payload: RequiredPayload) => Promise<Object>;
  setLoading!: MutationMethod;
  setError!: MutationMethod;
  deleteReportSuccess!: MutationMethod;
  setSnackBarData!: MutationMethod;
  clearControllers!: MutationMethod;
  clearReports!: MutationMethod;

  @Watch('isAuthorized') fetchReports(val: boolean) {
    if (val) {
      this.setDateNowFromBaugeld(this.date);
      this.getProduct();
    }
  }
  get styleContent() {
    const height = window.innerHeight - 310;
    return {
      'min-height': `${height}px`,
    };
  }
  get type() {
    return getType(this.$router.currentRoute.name);
  }
  get fileName() {
    if ((this.currentObjForSaving as any)?.title) {
      return (this.currentObjForSaving as any).title
        .replace(/[^a-zA-ZßäüöÄÖÜ0-9,.\s]/gi, ' ')
        .replace(/^\s+|\s+$/g, '')
        .replace(/\s+/g, '_');
    }
  }
  handleChangeConfirmModal(val) {
    this.currentObjForSaving = {};
    this.dialog = val;
  }

  goWizard() {
    this.clearResult();
    this.clearTableData();
    this.$router.push('/baugeld/wizard');
  }

  goSingleReport(item) {
    if (this.$router.currentRoute.fullPath !== `baugeld/report/${item.slug}`) {
      this.$router.push(`baugeld/report/${item.slug}`).catch(() => {});
    }
  }
  async getProduct() {
    try {
      const res = await this.fetchReportTypes();
      await this.setReportType(res.data);
      await this.fetchAllReports(this.report_type);
    } catch (error: any) {
      this.setError(true);
    }
  }
  setItem(item) {
    this.currentObjForSaving = item;
  }

  createPDF() {
    this.document = {
      show: true,
      headers: (this.currentObjForSaving as any).headers,
      rows: (this.currentObjForSaving as any).rows,
      type: this.report_type,
    };
    const date = reportDatePdf(this.$date, this.currentObjForSaving);

    this.isLoadingPdf = true;
    setTimeout(() => {
      const doc = document.getElementById('app');
      const elements = (doc as HTMLElement).getElementsByClassName(
        'v-dialog__content'
      );
      (elements as any).forEach((item) => item.classList.add('hidden'));
      savePDF(
        (this.$refs.document as Vue)?.$el,
        this.fileName,
        [],
        date,
        ''
      ).then(() => {
        this.isLoadingPdf = false;
        this.document.show = false;
      });
    });
  }

  saveXLS() {
    this.document = {
      show: true,
      headers: (this.currentObjForSaving as any).headers,
      rows: (this.currentObjForSaving as any).rows,
      type: this.report_type,
    };
    const metaDate = this.$date(
      (this.currentObjForSaving as any)?.meta?.data_time
    ).format('DD.MM.YYYY');
    //eslint-disable-next-line
    const date = metaDate ? `Es werden die Daten vom ${metaDate} angezeigt` : `Es werden die Daten vom  ${this.$date(new Date()).format('DD.MM.YYYY')} angezeigt`;

    this.isLoadingPdf = true;
    setTimeout(() => {
      const doc = document.getElementById('app');
      const elements = (doc as HTMLElement).getElementsByClassName(
        'v-dialog__content'
      );
      (elements as any).forEach((item) => item.classList.add('hidden'));
      exportXLS((this.$refs?.document as Vue)?.$el, this.fileName, date);
      this.isLoadingPdf = false;
      this.document.show = false;
    });
  }

  openDialog() {
    if (this.user?.isDemoUser && this.currentObjForSaving?.is_preconfigured) {
      this.setSnackBarData({
        text: this.$t('warnings.demoUserDeletingReport'),
        show: true,
        timeout: 3000,
        dark: false,
        color: 'orange',
      });
    } else {
      this.dialog = true;
    }
  }

  async copy() {
    try {
      this.setLoading(true);
      const res = await this.fetchCopyReport(
        (this.currentObjForSaving as any).slug
      );
      this.setLoading(false);
      return this.$router.push(`/baugeld/wizard/${(res as any).data.slug}`);
    } catch (e) {
      this.setLoading(false);
      this.setError(true);
    }
  }

  submitEditReport() {
    if (this.user.isDemoUser && this.currentObjForSaving.is_preconfigured) {
      this.setSnackBarData({
        text: this.$t('warnings.demoUserEditingReport'),
        show: true,
        timeout: 3000,
        dark: false,
        color: 'orange',
      });
    } else {
      return this.$router.push(
        `baugeld/wizard/${(this.currentObjForSaving as any).slug}`
      );
    }
  }

  async deleteCurrentReport() {
    this.isDeleting = true;
    try {
      await this.deleteReport({
        slug: (this.currentObjForSaving as any).slug,
        product_type: '3',
      });
      this.deleteReportSuccess({
        slug: (this.currentObjForSaving as any).slug,
        product_type: '3',
      });
      this.currentObjForSaving = {};
      this.isDeleting = false;
      this.dialog = false;
    } catch (e) {
      this.currentObjForSaving = {};
      this.isDeleting = false;
      this.dialog = false;
      this.setSnackBarData(this.snackBarData);
    }
  }

  created() {
    if (this.abortControllers) {
      this.abortControllers.forEach((item) => {
        item.abort();
      });
      this.clearControllers();
      this.clearReports({
        reports: ['allReportsFestgeld', 'allReportsTagesgeld'],
        loading: ['loadingAllReportsFestgeld', 'loadingAllReportsTagesgeld'],
      });
    }
    if (this.isAuthorized) {
      if (!this.allReports.length || this.updatedReportsBaugeld) {
        this.setDateNowFromBaugeld(this.date);
        this.getProduct();
      }
    }
  }
}
